import React from 'react'
import MainPage from '../../components/MainPage'

const Home = () => {
  return (
    <div>
      <MainPage />
    </div>
  )
}

export default Home
