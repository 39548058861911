import React from "react";
import SignupForm from "../../components/SignupForm";

const SignUpPage = () => {
  return (
    <>
      <SignupForm />
    </>
  );
};

export default SignUpPage;
