import { createContext, useContext } from 'react';
import './App.css';
import BorderImg from './components/BorderImg';
import Router from './components/Router';

function App() {

  return (
    <>

    <BorderImg />
      <Router />

    </>
  );
}

export default App;
